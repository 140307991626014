<template>
  <div >
    <div class="mapLeft" :style="{width: fullState? '0' : '350px'}">
      <div class="Putaway"  @click="Putaway()" :style="{right: fullState? '3px' : '340px'}">
        <img src="@/assets/btn.png" :style="{transform:fullState? 'rotate(180deg)': 'rotate(0deg)'}">
      </div>
      <el-form ref="searchForm" class="searchForm"  label-width="80px" style="padding-right: 40px;">
        <!-- <el-form-item label="建设年度" >
          <el-select size="mini" clearable @change="SearchLineDataChange" v-model="pageParamsLine.buildDate" style="width: 100%">
            <el-option
                v-for="item in yearArray"
                :key="item"
                :label="item"
                :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="埋设方式" >
          <el-select size="mini" @change="SearchLineDataChange" clearable v-model="pageParamsLine.buriedType" style="width: 100%">
            <el-option
                v-for="item in buryMode"
                :key="item"
                :label="item"
                :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item  label="压力级别" >
          <el-select size="mini" multiple clearable @change="SearchLineDataChange(1)" v-model="pageParamsLine.stressLevel" style="width: 100%;">
            <el-option
                v-for="item in pressuerType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="材质规格" >
            <el-cascader
            size="mini"
             style="width: 100%;"
            :options="texture"
            :show-all-levels="false"
            :props="{ multiple: true,value: 'code', label: 'name',}"
            clearable
            collapse-tags
            @change="SearchLineChange"></el-cascader>
        </el-form-item>
        <el-form-item  label="状态" >
          <el-select size="mini" multiple clearable @change="SearchLineDataChange(1)" v-model="pageParamsLine.useStatus" style="width: 100%;">
            <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.lable"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item  label="管径" > -->
          <!-- <el-input style="width:65%;margin-right:15px;"  size="mini" clearable v-model="pageParamsLine.bore" placeholder="请输入管径"></el-input> -->
          <!-- <el-button @click="searchLineData" type="primary" size="mini">查询</el-button> -->
        <div class="buttonBox">
          <el-button size="mini" type="primary" class="searchBtn" @click="addPoint" >添加管线</el-button>
          <el-button @click="replacement"  size="mini">重置</el-button>
        </div>  
        <!-- </el-form-item> -->
      </el-form>
        <!-- 管理页面页面 -->
        <div v-if="tabActive==1">
          <div style="width:100%;display: flex;justify-content: space-around">
          </div>
           <!-- <div class="topbt">
             <el-button size="mini" type="primary" class="searchBtn" @click="addPoint" >添加管线</el-button>
           </div> -->
            <div style="margin-top: 15px">
              <span style="margin: 0 20px">管线数量：{{totalLineNum}}个</span>
              <span style="margin: 0 20px;margin-left: 40px">总长度：{{(sumLengthNum/1000).toFixed(2)}}km</span>
            </div>

           <el-card class="box-card lineBox" v-for="(item,index) in tableData" :key="index">
              <p class="lineP">片区名称：{{item.networkName}}</p>
              <p class="lineP">管线总长度：{{(item.length/1000).toFixed(2)}}km</p>
              <p class="lineP">巡检点数量：{{item.count}}</p>
              <p class="lineB">
                <el-button type="primary" plain size="mini" @click="changeTab(2,item)">管理管线</el-button>
              </p>
           </el-card>
            <div class="pageBox">
              <el-pagination
                  small
                :current-page="pageParams.current"
                :background="true"
                :page-sizes="[10, 30, 50, 100]"
                :page-size="pageParams.size"
                layout="prev, pager, next"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
        </div>
        <div v-if="tabActive==2">
          <div class="topbt">
             <el-button size="mini" class="searchBtn" @click="changeTab(1)" >返回</el-button>
             <!-- <el-button size="mini" type="primary" class="searchBtn" @click="addPoint" >添加管线</el-button> -->
          </div>
            <div style="margin-top: 15px">
              <span style="margin: 0 20px">管线数量：{{totalLineNum}}个</span>
              <span style="margin: 0 20px;margin-left: 40px">总长度：{{(sumLengthNum/1000).toFixed(2)}}km</span>
            </div>
           <el-card class="box-card lineBox" v-for="(item,index) in tableDataLine" :key="index">
              <p class="lineP">管线名称：{{item.lineName}}</p>
              <p class="lineP">管线长度：{{item.length}}m</p>
              <p class="lineP">创建人：{{item.createBy}}</p>
              <p class="lineP">创建时间：{{item.createTime}}</p>
              <p class="lineB" >
                <el-button type="primary" plain size="mini" @click="editFun(item)">编辑</el-button>
                <el-button type="danger" plain size="mini" @click="delFun(item.id)">删除</el-button>
              </p>
           </el-card>
            <div class="pageBox">
              <el-pagination
                  small
                :current-page="pageParamsLine.current"
                :background="true"
                :page-sizes="[10, 30, 50, 100]"
                :page-size="pageParamsLine.size"
                layout="prev, pager, next"
                :total="totalLine"
                :page-count="pagerCount"
                @size-change="handleSizeChangeLine"
                @current-change="handleCurrentChangeLine"
              >
              </el-pagination>
            </div>
        </div>
    </div>
    <div class="mapRight" ref="mapRightRef" id="mapRightId">
          <subMap ref="subMap" :modeal='1' :layer-name="layerName" :layers="layers" :layer-code="layerCode" @setLocation="setLocation"/>  
          <LineForm @getListArea="getListArea" @GetNetworkDetailFun="GetNetworkDetailFun" @resetData="resetData" ref="formBox" :showForm="showForm" @save="save" @closePDraw="closePDraw" :addForm='addForm'/>
    </div>
  </div>
</template>

<script>
import { LoadMixin } from '@/common/amapLoad'
import subMap from '@/views/mapTool/subMap'
import LineForm from '@/views/gisMap/commonForm/LineForm'
import { patrolGetDict } from "@/RequestPort/maintenance";
import { getNetwork,getLinePage,lineDelete,GetNetworkDetail,selectDictListByParentCode,lineGet } from '@/apis/commonType'

export default {
  name: 'GisMap',
  components: {subMap ,LineForm},
  mixins: [LoadMixin],
  data() {
    return {
      name: '管线',
      pagerCount:3,
      // 管线新增编辑参数
      addForm:{
        lineName:'',
        useStatus:'',
        gasType:'',
        stressLevel:'',
        material:'',
        buriedType:'',
        styleColor:'#0062FF',
        location:'',
        networkId:'',
        length:0,
        burialDepth:'',
        bore:'',
        buildDate:'',
        through:'',
      },
      layerName: '',
      layerCode: '',
      // 管理列表参数
      pageParams: {
        current: 1,
        size: 100
      },
      // 详细列表参数
      pageParamsLine: {
        current: 1,
        size: 100,
        buildDate:'',
        bore:'',
        buriedType:'',
        networkId:'',
        stressLevel:'',

      },
      // 详细总条数
      totalLine: 0,
      // sumLength:'',
      // 管线数量
      totalLineNum:0,
      // 管线总长度
      sumLengthNum :'',
      // 管理总条数
      total: 0,
      // areaData:[],
      // 管理列表内容
      tableData:[],
      // 详细列表内容
      tableDataLine:[],  
      // 展示管理页面还是详细页面    
      tabActive:1,
      showForm:false,
      // NetWorkDetail:{},
      AllLineNum:0,
      layers:"'patrol_lines'",
      // ,'patrol_networks'
      // searchLineForm:{
      //   buildDate:'',
      //   bore:'',

      // },
      // 建设年度
      yearArray:[],
      // 获取当前年分
      nowYear:'',
      // buryMode:[
      //   '地下',
      //   '架空',
      //   '穿越',
      // ],
      // 状态
      statusList:[
        {lable:'在建',value:'0'},
        {lable:'停用',value:'1'},
        {lable:'在用',value:'2'},
        {lable:'规划',value:'3'},
      ],
      // 材质
      texture:[],
      // 压力级别
      pressuerType:[],
      searchTo:0,
      // 管线管理弹窗的展示隐藏
      fullState:false,
      // 显示编辑
      // compile:false
    }
  },
  computed: {},
  mounted() {
    //  获取管理列表
    this.getListArea()
    // 获取管线个数和总长度
    lineGet({...this.pageParamsLine}).then((e)=>{
     this.totalLineNum = e.data.count;
     this.sumLengthNum = e.data.length;
    })
    // 获取建设年度
    this.nowYear = new Date().getFullYear()
    this.yearArrayFun()
    //获取压力级别
    selectDictListByParentCode({parentCode:512}).then((e)=>{
      this.pressuerType = e.data
    })
    // 获取材质
    patrolGetDict({pid:'524,518,70,46'}).then(res => {
        if(res.code == 200) {
          this.texture = res.data[0].children[0].children
        } else {
          this.$message.error(res.msg)
        }
      })
  },
  methods: {
    /**
      * 重置
     */
    replacement(){
      // this.pageParamsLine= {
      //   current: 1,
      //   size: 100,
      //   buildDate:'',
      //   bore:'',
      //   buriedType:'',
      //   networkId:'',
      //   stressLevel:'',
      //   material : '',
      //   useStatus : ''
      // }
      this.tabActive = 1
      this.pageParams = {
        current: 1,
        size: 100,
      }
      // 更新管理列表
      this.getListArea()
      // 获取管线数量和总长度
      lineGet(this.pageParams ).then((e)=>{
        this.totalLineNum = e.data.count;
        this.sumLengthNum = e.data.length;
      })
      // 关闭弹窗
      this.closePDraw()
      // this.SearchLineDataChange()
    },
    /**
     * 点击收回（右侧菜单展示）
     */
    Putaway() {
      this.fullState = !this.fullState
      // this.$store.commit('bottom/FullStateChange', !state)
    },
    /**
     * 级联菜单选择
     */
    SearchLineChange(val){
      let materialList = []
      val.forEach(el => {
            materialList.push(el[1])
      })
      this.pageParamsLine.material = materialList
      // 刷新列表
      this.SearchLineDataChange(1)
      
    },
    /**
     * 添加管线（取消清空数据列表）
     */
    resetData(){
      this.addForm = {
        lineName:'',
        useStatus:'',
        gasType:'',
        stressLevel:'',
        material:'',
        buriedType:'',
        styleColor:'#0062FF',
        location:'',
        networkId:'',
        length:0,
        burialDepth:'',
        bore:'',
        buildDate:'',
        through:'',
      }
    },
    /**
     * 查询列表（详细列表数据）
     */
    SearchLineDataChange(val){
      //  this.compile = val == 1 ? false : true
      let p = {
        ...this.pageParamsLine,
      }
      p.stressLevel = p.stressLevel+''
      p.material = p.material+''
      p.useStatus = p.useStatus+''
      // 详细列表数据
      getLinePage(p).then(res => {
        this.totalLine = res.data.total
        this.tableDataLine = res.data.records
        this.tabActive = 2
        this.searchTo = 1
      })
      // 获取管线数量和总长度
      lineGet(p).then((e)=>{
        this.totalLineNum = e.data.count;
        this.sumLengthNum = e.data.length;
      })
    },
    // searchLineData(){
    //   let p = {
    //     ...this.pageParamsLine,
    //   }
    //   getLinePage(p).then(res => {
    //     this.totalLine = res.data.total
    //     this.tableDataLine = res.data.records
    //     this.tabActive = 2
    //   })

    //   lineGet(p).then((e)=>{
    //       this.totalLineNum = e.data.count;
    //       this.sumLengthNum = e.data.length;
    //  })


    // },
    /**
     * 建设年份只能获取2007以上的
     */
    yearArrayFun(){
      if(this.nowYear >= 2016){
          this.yearArray.push(this.nowYear)
          this.nowYear --
          this.yearArrayFun()
      }else{
        return
      }
    },
    //  reloadList(){
    //    this.getList2()
    // },
    /**
     * 编辑管线
     */
    editFun(item){
      this.closePDraw()
      let data={
        data:item,
        type:'patrol_lines'
      }
      // 打开编辑弹窗
      this.$eventBus.$emit('vectorEdit', data )
    },
    /**
     * 删除（详细列表）
     */
     delFun(id){
      this.$confirm(`确定要删除吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {       
            let p = {
                id: id 
              }
              lineDelete(p).then(res => {
                if(res.code==200){
                  this.$eventBus.$emit('reloadLayer')
                  this.$message.success(res.msg)
                  this.getList2()
                  this.GetNetworkDetailFun()
                }                
              })
      })
       
    },
    /**
     * 跳转管理列表页面和详细列表页面
     */
    changeTab(type,item){
      this.tabActive=type
      // 进入到详细列表页面
      if(type==2){
        // this.compile = true
         this.pageParamsLine={
            current: 1,
            size: 100,
            networkId:item.id
          }
          if(item.location){
            this.$store.commit('ChangePoly', item)
            this.$eventBus.$emit('showPolygon',item.location)
          }
        // this.sumLength = item.length
        // this.NetWorkDetail = item
        // this.GetNetworkDetailFun(item)
        // 获取详细列表
        this.getList2()
      }else{
        console.log(this.searchTo)
        if(this.searchTo == 0){
            this.$eventBus.$emit('clearPolygon')
            this.getListArea()
            this.$store.commit('ChangePoly', {})
        }
        this.pageParamsLine={
            current: 1,
            size: 100,
            networkId:'',
            stressLevel:'',
            material : '',
            useStatus : ''
          }
          // this.SearchLineDataChange()
          // 加载管理列表
         this.getListArea()
         // 关闭弹窗
         this.closePDraw()
          // this.$eventBus.$emit('clearPolygon')
          // lineGet(this.pageParamsLine).then((e)=>{
          //   this.totalLineNum = e.data.count;
          //   this.sumLengthNum = e.data.length;
          // })
      }
      this.searchTo = 0
      //
    },
    GetNetworkDetailFun(item){
      GetNetworkDetail({id:item.id}).then((e)=>{
        //this.sumLength =e.data.length
        this.AllLineNum = e.data.location.length
      })
    },
    addPoint(){
      this.$message.success('请先在地图上点选位置，然后填写右侧表单')
      this.$eventBus.$emit('closeEdit')
      this.$refs.subMap.getLocation('line')
    },
    getMapDistanceApi(position1,position2){
      var lng1 = position1[0]
      var lat1 = position1[1]
      var lng2 = position2[0]
      var lat2 = position2[1]
      var radLat1 = lat1*Math.PI / 180.0;
      var radLat2 = lat2*Math.PI / 180.0;
      var a = radLat1 - radLat2;
      var  b = lng1*Math.PI / 180.0 - lng2*Math.PI / 180.0;
      var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a/2),2) +
          Math.cos(radLat1)*Math.cos(radLat2)*Math.pow(Math.sin(b/2),2)));
      s = s *6378.137 ;
      s = Math.round(s * 10000) / 10000;
      s = s * 1000
      return s;
    },
    setLocation(point){
      this.addForm.locations=point
      let allLength = 0
      point.forEach((item,index)=>{
        if(index < point.length-1){
          allLength += this.getMapDistanceApi([item.lng,item.lat],[point[index+1].lng,point[index+1].lat])
        }
      })
      this.addForm.length = allLength.toFixed(2)

      this.showForm=true
      
      let data={
        data:{coordinate:point},
        type:'patrol_lines_add'
      }
      this.$eventBus.$emit('vectorEdit', data )
      this.$refs.subMap.clearDraw()

    },
    /**
     * 取消（关闭弹窗）
     */
    closePDraw(){
     this.$refs.formBox.resetFields()
     this.$refs.subMap.clearLocation()
     this.$eventBus.$emit('closeEdit')
     this.showForm=false
    },
   
    save(){
          // this.getListArea()
          this.getList2()
          this.closePDraw()
          this.$eventBus.$emit('reloadLayer')
          this.$eventBus.$emit('closeEdit')
       
    },
    // getList(){
    //   this.getListArea()
    //       this.getList2()
    // },
    /**
     * 获取详细列表
     */
    getList2() {
      let p = {
        ...this.pageParamsLine,
      }
      // 获取列表数据
      getLinePage(p).then(res => {
        this.totalLine = res.data.total
        this.tableDataLine = res.data.records
      })
      // 获取数量和总长度
      lineGet(p).then((e)=>{
        this.totalLineNum = e.data.count;
        this.sumLengthNum = e.data.length;
      })
    },
    /**
     * 查询列表（管理列表）
     */
    getListArea() {
      let p = {
        ...this.pageParams,
        ...this.searchForm
      }
      getNetwork(p).then(res => {
        this.total = res.data.total
        this.tableData = res.data.records
      })
    },
    handleSizeChange(val) {
      this.pageParams.size = val
      this.getListArea()
    },
    handleCurrentChange(val) {
      this.pageParams.current = val
      this.getListArea()
    },
    /**
     * 列表下面的分页
     */
    handleSizeChangeLine(val) {
      this.pageParamsLine.size = val
      this.getList2()
    },
    handleCurrentChangeLine(val) {
      this.pageParamsLine.current = val
      this.getList2()
    },
  }
}
</script>

<style lang="less" scoped>
.mapLeft {
  height: calc(100vh - 60px);
  width: 350px;
  background: #fff;float:right;overflow:auto;
}
.topbt{text-align: right;margin-right:20px;margin-top:10px;}
.mapRight{
  width: auto;
  margin-left:0;position: relative;overflow: hidden;
  height:100%;

}
.pageBox{width:100%;text-align: center}
.lineBox{margin:15px;border:1px solid rgb(240, 240, 240);border-radius: 8px;
.lineP{margin-top:5px;}
.lineB{margin-top:10px;}
}
// 按钮盒子
.buttonBox{
  display: flex;
   justify-content:center;
   .el-button{
     width: 104px;
   }
}
.Putaway {
  background: #737E95;
  position: absolute;
  top: calc((100% - 0.46rem) / 2);
  // right: 5.25rem;
  // right: 340px;
  color: var(--title-text-color);
  display: flex;
  align-items: center;
  font-size: 0.15rem;
  justify-content: center;
  cursor: pointer;
  width: 0.1rem;
  z-index: 8;
  height: 0.56rem;
}
</style>
